<template>
    <pagination title="Costings" :details-open="viewUser" :response="response" :entities="entities" :filterAction="filterAction" header-colour-class="bg-transparent" activeClass="bg-v3-light-green bg-opacity-20">
        <template v-slot:additional-buttons>
            <slot name="additional-buttons"></slot>
        </template>
        <template v-slot:headers>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6">Name
            </th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Ordered</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Unpaid</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Invoices</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Rentals</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Vouchers</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Day Work</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Total</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Budget</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Remaining</th>
        </template>
        <template v-slot:items>
            <template v-for="entity in entities">
                <tr @click="navigateToItem(entity)" class="hover:bg-v3-gray-50 hover:bg-opacity-20 cursor-pointer bg-v3-light-green bg-opacity-20 text-v3-gray-800 dark:text-v3-gray-200">
                    <td class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium sm:pl-6">
                        {{entity.entity.properties.get('project_name')}}
                    </td>
                    <td class="whitespace-normal px-3 py-4 text-sm">
                        <div>
                            <div>
                                {{entity.entity.properties.get('quotes_total')}}
                            </div>
                            <div v-if="entity.entity.properties.get('quotes_euro_total')">
                                {{entity.entity.properties.get('quotes_euro_total')}}
                            </div>
                        </div>
                    </td>
                    <td class="whitespace-normal px-3 py-4 text-sm">
                        <div>
                            <div>
                                {{entity.entity.properties.get('unpaid_total')}}
                            </div>
                            <div v-if="entity.entity.properties.get('euro_unpaid_total')">
                                {{entity.entity.properties.get('euro_unpaid_total')}}
                            </div>
                        </div>
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm">
                        <div>
                            <div>
                                {{entity.entity.properties.get('invoices_total')}}
                            </div>
                            <div v-if="entity.entity.properties.get('euro_invoices_total')">
                                {{entity.entity.properties.get('euro_invoices_total')}}
                            </div>
                        </div>
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm">
                        {{ entity.entity.properties.get('rentals_total') }}
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm">
                        {{ entity.entity.properties.get('payment_vouchers_total') }}
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm">
                        £0.00
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm">
                        <div>
                            <div>
                                {{entity.entity.properties.get('overall_total')}}
                            </div>
                            <div v-if="entity.entity.properties.get('euro_overall_total')">
                                {{entity.entity.properties.get('euro_overall_total')}}
                            </div>
                        </div>
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm">
                        <div>
                            <div>
                                {{entity.entity.properties.get('budget')}}
                            </div>
                            <div v-if="entity.entity.properties.get('euro_budget')">
                                {{entity.entity.properties.get('euro_budget')}}
                            </div>
                        </div>
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm">
                        <div>
                            <div>
                                {{entity.entity.properties.get('remaining')}}
                            </div>
                            <div v-if="entity.entity.properties.get('euro_remaining')">
                                {{entity.entity.properties.get('euro_remaining')}}
                            </div>
                        </div>
                    </td>
                </tr>
                <tr @click="navigateToSite(site)" v-for="(site, index) in entity.entity.properties.get('sites')" class="hover:bg-v3-gray-50 hover:bg-opacity-20 cursor-pointer text-v3-gray-800 dark:text-v3-gray-200">
                    <td class="whitespace-nowrap px-3 py-4 text-sm">
                        {{ site['site_name'] }}
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm">
                        <div>
                            <div>
                                {{site['quotes_total']}}
                            </div>
                            <div v-if="site['quotes_euro_total']">
                                {{site['quotes_euro_total']}}
                            </div>
                        </div>
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm">
                        <div>
                            <div>
                                {{site['unpaid_total']}}
                            </div>
                            <div v-if="site['euro_unpaid_total']">
                                {{site['euro_unpaid_total']}}
                            </div>
                        </div>
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm">
                        <div>
                            <div>
                                {{site['invoices_total']}}
                            </div>
                            <div v-if="site['euro_invoices_total']">
                                {{site['euro_invoices_total']}}
                            </div>
                        </div>
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm">
                        {{site['rentals_total']}}
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm">
                        {{site['payment_vouchers_total']}}
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm">
                        £0.00
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm">
                        <div>
                            <div>
                                {{site['overall_total']}}
                            </div>
                            <div v-if="site['euro_overall_total']">
                                {{site['euro_overall_total']}}
                            </div>
                        </div>
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm">
                        <div>
                            <div>
                                {{site['budget']}}
                            </div>
                            <div v-if="site['euro_budget']">
                                {{site['euro_budget']}}
                            </div>
                        </div>
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm">
                        <div>
                            <div>
                                {{site['remaining']}}
                            </div>
                            <div v-if="site['euro_remaining']">
                                {{site['euro_remaining']}}
                            </div>
                        </div>
                    </td>
                </tr>



            </template>
        </template>
    </pagination>
</template>
<script>
import Pagination from "@/v3/components/pagination/Pagination.vue";
import axios from "axios";
import {mapGetters} from "vuex";
import Loading from "@/components/Loading.vue";
import HrSinglePersonPagination from "@/v3/components/pagination/HrSinglePersonPagination.vue";
import StatusBadge from "@/components/StatusBadge.vue";
import FlagOrderAction from "@/v2/components/FlagOrderAction.vue";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import Siren from "super-siren";
let config = {
    rounding: Math.floor
}
dayjs.extend(duration);
dayjs.extend(relativeTime, config);
export default {
  name: 'CostingsPagination',
    data(){
      return {
        viewUser: false,
          details: null,
          detailsOpen: false
      }
    },
    components: {FlagOrderAction, StatusBadge, HrSinglePersonPagination, Loading, Pagination},
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
            user: "getUser",
        }),
        initials() {
            if (this.details && this.details.properties['name']) {
                return this.details.properties['name']
                    .replace(/[^a-zA-Z- ]/g, "")
                    .match(/\b\w/g)
                    .join("");
            }
        },
        filterAction(){
            return this.response.body.actions.filter(action => action.name === 'filter').first();
        }
    },
  props: {
    entities: {},
      response: {
        type: Object,
          default: null
      }
  },
    methods:{
        navigateToItem(orderEntity){
            let selfLink = orderEntity.entity.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            this.$router.push(parts[1]);
        },
        navigateToSite(site) {
            let selfLink = site['site_link']
            let parts = selfLink.split('/api');
            this.$router.push(parts[1]);
        },
    }
}
</script>

<template>
  <div class="bg-v3-light-green bg-opacity-20 rounded-full h-fit dark:bg-v3-gray-50/20">
      <loading v-if="!links"></loading>
      <template v-else>
    <div class="h-full p-2">
      <nav class="flex flex-1 flex-col w-min h-full" aria-label="Sidebar">
        <ul role="list" class="space-y-1 flex flex-col justify-between h-full">
          <div>
            <navigation-link name="Costings" :is-active="isCurrentPage('/costing')" :open="panelOpen" href="/costing" activeClass="text-v3-white-100 bg-v3-light-green dark:text-v3-gray-900" additional-classes="text-v3-light-green">
              <template v-slot:icon>
                  <svg class="h-6 w-6 shrink-0 fill-current" width="23" height="19" viewBox="0 0 23 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M13.56 7.94L11.5 7L13.56 6.06L14.5 4L15.44 6.06L17.5 7L15.44 7.94L14.5 10L13.56 7.94ZM3.5 12L4.44 9.94L6.5 9L4.44 8.06L3.5 6L2.56 8.06L0.5 9L2.56 9.94L3.5 12ZM8 7L9.09 4.59L11.5 3.5L9.09 2.41L8 0L6.91 2.41L4.5 3.5L6.91 4.59L8 7ZM4 18.5L10 12.49L14 16.49L22.5 6.93L21.09 5.52L14 13.49L10 9.49L2.5 17L4 18.5Z" fill="currentColor"/>
                  </svg>

              </template>
            </navigation-link>

          </div>
            <div class="pt-12">
                <navigation-link name="My Dash" :open="panelOpen" href="/"  activeClass="text-v3-light-green" additional-classes="text-v3-light-green ring-2 ring-current">
                    <template v-slot:icon>
                        <svg class="h-6 w-6 shrink-0" width="25" height="24" viewBox="0 0 25 24" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.5 21V9L12.5 3L20.5 9V21H14.5V14H10.5V21H4.5Z" fill="currentColor"/>
                        </svg>
                    </template>
                </navigation-link>
            </div>
        </ul>
      </nav>
    </div>
      </template>
  </div>
</template>
<script>
import NavigationLink from "@/v3/components/navigation/NavigationLink.vue"
import Loading from "@/components/Loading.vue";

export default {
  name: 'CostingNavigation',
  components: {NavigationLink, Loading},
    data(){
      return {
          panelOpen: true
      };
    },
    props:{
      links: {
          type: Object,
          default: null
      }
    },
    methods:{
      isCurrentPage(url)
      {
          return this.$route.path.includes(url)
      },
        hasLink(key){
            if(this.links) {
                return this.links.filter(link => link.rels.contains(key)).size;
            }
            return false;
        },
    },

}
</script>
